import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import List from '@mui/material/List';

import Header from '../components/Header.js';
import Footer from '../components/Footer.js';


import {ThemeProvider} from '@mui/material/styles';
import theme from '../components/Theme';
import { ListItem } from '@mui/material';

function AboutOrganization() {
  return (
    <Container maxWidth="xl" component="main">
      <ThemeProvider theme={theme}>
      <Header />

      <Box margin="auto" sx={{ pt: 4, pb: 4, width: {xs:"100%", xl: "75%"} }}>
        <Typography component="h4" variant="h4" color="text.primary" gutterBottom>
          About the Organization
        </Typography>

        <Typography component="h5" variant="h5" color="text.primary" gutterBottom>
          Purpose
        </Typography>
        <Typography component="p" variant="body1" color="text.primary">
          <b>
          Front Range Trails Conservancy was formed in the fall of 2022 to
          protect and advance public lands across the Front Range of
          the Rocky Mountains by effecting equitable, effective, and lasting change
          through trail and advocacy projects. These conservation efforts are
          undertaken by volunteers out of their love of the land.
          </b>
        </Typography>
        <br/>
        <Typography component="p" variant="body1" color="text.primary">
          As a volunteer organization, FRTC is for everyone. Parks and trails need
          maintenance just like our roads do, and we want to enable trail users of all
          kinds—hikers, bikers, runners, equestrians, you name it—to be a part
          of leaving the land better than they found it.
        </Typography>
        <br/>
        <Typography component="p" variant="body1" color="text.primary" gutterBottom>
          Whether it's through digging in the dirt on a trail maintenance project
          or organizing a campaign to inform users of changes that could impact
          their recreation, FRTC is an inclusive environment for those who believe
          in our purpose and want to leave a positive impact on the lands we love.
          Successful conservation takes people with all kinds of experiences.
        </Typography>

        <Typography component="h5" variant="h5" color="text.primary" gutterBottom>
          Nonprofit
        </Typography>
        <Typography component="p" variant="body1" color="text.primary">
          <b>
          Front Range Trails Conservancy is registered with the Colorado Secretary of State's office
          as a nonprofit corporation <Link href="https://www.coloradosos.gov/biz/BusinessEntityDetail.do?masterFileId=20228280772" target="_blank" rel="noopener">(Business ID 20228280772)</Link>,
          formed on January 1, 2023.
          </b>
        </Typography>
        <br/>
        <Typography component="p" variant="body1" color="text.primary" gutterBottom>
          We intend to seek tax exempt status with the IRS under Section 501(c)(3),
          thus granting tax deductible status to contributions under Section 170,
          as leadership within our organization grows. In the meantime, FRTC operates
          exclusively for educational and charitable purposes under that code per
          our Articles of Incorporation, available at our Secretary of State filing
          page linked above.
        </Typography>
        <br/>
        <Typography id="where-does-the-money-go" component="h6" variant="h6" color="text.primary" gutterBottom>
          Where does the money go?
        </Typography>
        <Typography component="p" variant="body1" color="text.primary">
          <b>
          Looking after land isn't free. While we're made up of crews that volunteer
          our time, we're still an organization with bills to pay. As such, we want our
          community to know what money goes for when we ask for contributions.
          </b>
        </Typography>
        <br/>
        <Typography component="p" variant="body1" color="text.primary">
          Luckily we're starting out 2023 with a set of nearly new donated tools,
          but over time we'll need to replace and add to the tool cache. Beyond putting
          tools in people's hands, there are costs to running ongoing trail projects
          along with investments into leadership that we'd like to make:
          <List sx={{ listStyleType: 'disc'}}>
            <ListItem sx={{ display: 'list-item' }}>Training: trail construction, wilderness first aid, leadership</ListItem>
            <ListItem sx={{ display: 'list-item' }}>Tools: replacement over time, adding to meet growth and project needs, centralized storage location</ListItem>
            <ListItem sx={{ display: 'list-item' }}>Projects: buying lunch, snacks, post-work drinks</ListItem>
          </List>
          Some of these are expenses we'll look to cover via grants that are
          available to organizations like ours via the trails and outdoors communities.
          Eventually growing into a 501(c)(3) nonprofit will expand our ability
          to obtain grants while being even more efficient with the money due to
          the improved tax situation, which is a win for everyone, especially the trails.
        </Typography>
      </Box>

      <Footer />
      </ThemeProvider>
    </Container>
  );
}

export default AboutOrganization;
