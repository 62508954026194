import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Unstable_Grid2';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import InstagramIcon from '@mui/icons-material/Instagram';

import Header from '../components/Header.js';
import Footer from '../components/Footer.js';


import {ThemeProvider} from '@mui/material/styles';
import theme from '../components/Theme';

function Contact() {
  return (
    <Container maxWidth="xl" component="main">
      <ThemeProvider theme={theme}>
      <Header />

      <Box margin="auto" sx={{ pt: 4, pb: 4, width: {xs:"100%", xl: "75%"} }}>
          <Typography component="h3" variant="h3" color="text.primary" gutterBottom>
            Contact Us
          </Typography>

        <Box sx={{ pt: 4, }}>
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item md={1}>
              <MailOutlineIcon/>
            </Grid>
            <Grid item md={11}>
              <Typography component="p" variant="body1" color="text.primary" gutterBottom>
              Front Range Trails Conservancy
              <br/>
              PO Box 620801
              <br/>
              Littleton, CO 80162
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ pt: 4, }}>
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item md={1}>
              <AlternateEmailIcon/>
            </Grid>
            <Grid item md={11}>
              <Typography component="p" variant="body1" color="text.primary" gutterBottom>
                <Link href="mailto:howdy@frtrails.org">
                  howdy@frtrails.org
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ pt: 4, }}>
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item md={1}>
              <InstagramIcon/>
            </Grid>
            <Grid item md={11}>
              <Typography component="p" variant="body1" color="text.primary" gutterBottom>
                <Link href="https://instagram.com/frtrails" target="_blank" rel="noopener">
                  @frtrails
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Footer />
      </ThemeProvider>
    </Container>
  );
}

export default Contact;
