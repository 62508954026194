import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import {Link as RouterLink} from "react-router-dom";

import {FormContainer, TextFieldElement} from 'react-hook-form-mui'

import Header from "../components/Header.js";
import Footer from "../components/Footer.js";

import {ThemeProvider} from "@mui/material/styles";
import theme from "../components/Theme";

import { useState, forwardRef } from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function NewsletterSignup() {
  const [openSuccessSnack, setSuccessSnackOpen] = useState(false);
  const [openAlreadySnack, setAlreadySnackOpen] = useState(false);
  const [openWarningSnack, setWarningSnackOpen] = useState(false);

  const submitForm = async (data) => {
    data.wants_email_newsletter = true;
    data.wants_email_notifications = true;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json"},
      body: JSON.stringify(data)
    };

    try {
      const response = await fetch("/api/volunteer", requestOptions);

      if(response.status === 201) {
        setSuccessSnackOpen(true);
      } else if (response.status === 400) {
        setAlreadySnackOpen(true);
      } else {
        setWarningSnackOpen(true);
      }
    } catch (err) {
      console.log(err);
      setWarningSnackOpen(true);
      return;
    }
  }

  const handleSuccessSnackClose = (event, reason) => {
    // Skip closing if they click outside
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackOpen(false);
  };

  const handleAlreadySnackClose = (event, reason) => {
    // Skip closing if they click outside
    if (reason === 'clickaway') {
      return;
    }

    setAlreadySnackOpen(false);
  };

  const handleWarningSnackClose = (event, reason) => {
    // Skip closing if they click outside
    if (reason === 'clickaway') {
      return;
    }

    setWarningSnackOpen(false);
  };

  return (
    <Container maxWidth="xl" component="main">
    <ThemeProvider theme={theme}>
    <Header />

    <Box margin="auto" sx={{ pt: 4, pb: 4, width: {sm:"100%", xl: "75%"} }}>
      <Typography component="h4" variant="h4" align="justify" color="text.primary" gutterBottom>
        Sign up for our newsletter!
      </Typography>
      <Typography component="p" variant="subtitle1" align="justify" color="text.primary" gutterBottom>
        Stay up to date on projects, events, advocacy actions, and news
        about what we're up to.
      </Typography>
      <FormContainer onSuccess={data => submitForm(data)} >
        <TextFieldElement required margin={'dense'} label={'First Name'} name={'first_name'} variant={"standard"} /><br/>
        <TextFieldElement required margin={'dense'} label={'Last Name'} name={'last_name'} variant={"standard"} /><br/>
        <TextFieldElement required type={'email'} margin={'dense'} label={'Email'} name={'email'} variant={"standard"} /><br/>
        <Button type={'submit'} color={'primary'} variant={'contained'}>Sign me up!</Button>
      </FormContainer>
      <br/>
      <Typography component="p" variant="body1" align="justify" color="text.primary" gutterBottom>
        We aim to send emails roughly once a month. We'd rather be busy outside
        than spending our time on email, and we know you would too. We value your
        connection with us and will only use your email address in accordance
        with our <Link component={RouterLink} to="/privacy">privacy policy</Link>.
      </Typography>
    </Box>


    <Snackbar open={openSuccessSnack} autoHideDuration={4000} onClose={handleSuccessSnackClose}>
      <Alert onClose={handleSuccessSnackClose} severity="success" sx={{ width: '100%' }}>
        Thanks for subscribing!
      </Alert>
    </Snackbar>
    <Snackbar open={openAlreadySnack} autoHideDuration={4000} onClose={handleAlreadySnackClose}>
      <Alert onClose={handleAlreadySnackClose} severity="info" sx={{ width: '100%' }}>
        You're already subscribed!
      </Alert>
    </Snackbar>
    <Snackbar open={openWarningSnack} autoHideDuration={4000} onClose={handleWarningSnackClose}>
      <Alert onClose={handleWarningSnackClose} severity="warning" sx={{ width: '100%' }}>
        Error subscribing :( Please email howdy@frtrails.org
      </Alert>
    </Snackbar>

    <Footer />
    </ThemeProvider>
    </Container>
  )
}
