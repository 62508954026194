import React from 'react';

import Main from "./Main";

function App() {
    return (
        <Main />
    );
    }

export default App;
