import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Header from '../components/Header.js';
import Footer from '../components/Footer.js';


import {ThemeProvider} from '@mui/material/styles';
import theme from '../components/Theme';

function PrivacyPolicy() {
  return (
    <Container>
    <ThemeProvider theme={theme}>
    <Header />

    <Container disableGutters maxWidth="md" component="main" sx={{ pt: 4, pb: 1 }}>
      <Typography component="h4" variant="h4" color="text.primary" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography component="p" variant="body1" color="text.secondary">
        Any data collected by this website is used only for the purposes of
        conducting Front Range Trails Conservancy business. No data of any type
        is or ever will be sold or shared with third parties.
      </Typography>
      <br/>
      <Typography component="p" variant="body1" color="text.secondary">
        If you provided your email and/or phone number to this website, you will
        only be contacted for business purposes, including newsletters if you have
        subscribed to them, and notifications for projects you have signed up
        for—including confirmation, cancellation, reminder, rescheduling,
        and followup—by text message and/or email depending on your preferences.
      </Typography>
    </Container>

    <Footer />
    </ThemeProvider>
    </Container>
  );
}

export default PrivacyPolicy;
