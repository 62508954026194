import { createTheme } from '@mui/material/styles';

// From https://m2.material.io/resources/color/#!/?view.left=0&view.right=1&secondary.color=0277BD&primary.color=0D47A1

const theme = createTheme({
    palette: {
      primary: {
        light: '#5472d3',
        main: '#0d47a1',
        dark: '#002171',
        contrastText: '#eaeaea',
      },
      secondary: {
        light: '#58a5f0',
        main: '#0277bd',
        dark: '#004c8c',
        contrastText: '#eaeaea',
      },
      // Custom light pallete
      light: {
        light: '#ffffff',
        main: '#eaeaea',
        dark: '#b8b8b8',
        contrastText: '#0d47a1',
      },
      text: {
        primary: '#002171',
        secondary: '#0277bd',
        light: '#eaeaea'
      }
    },
  });

export default theme;
