import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGrid } from '@mui/x-data-grid';
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import Header from "../components/Header.js";
import Footer from "../components/Footer.js";

import {ThemeProvider} from "@mui/material/styles";
import theme from "../components/Theme";

import moment from 'moment';

export default function Project() {
  const { id } = useParams();
  const [projectData, setProjectData] = useState([])
  const [crewMembersData, setCrewMembersData] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const May = new Date("2023-05-01");

  useEffect(() => {
    ProjectBody()
  }, [isLoading])

  useEffect(() => {
    fetchProject(id) // Fetch projects when component is mounted
    fetchCrewMembers(id)
  }, [id])

  const fetchProject = (project) => {
    setIsLoading(true);
    fetch("/api/project/" + project, {method: "GET"})
      .then((res) => res.json())
      .then((result) => setProjectData(result.project))
      .catch((err) => console.log(JSON.stringify({error: err})))
      .finally(() => setIsLoading(false))
  }

  const fetchCrewMembers = (project) => {
    setIsLoading(true);
    fetch("/api/crew_members/" + project, {method: "GET"})
      .then((res) => res.json())
      .then((result) => setCrewMembersData(result.crew_members))
      .catch((err) => console.log(JSON.stringify({error: err})))
      .finally(() => setIsLoading(false))
  }


  const formatFullDate = (date) => moment(date).format("dddd MMMM Do, YYYY h:mm a");
  const formatEnding = (date) => moment(date).format("h:mm a");

  const crew_members_columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'first_name', headerName: 'First Name', type: 'string', width: 100 },
    { field: 'last_name', headerName: 'Last Name', type: 'string', width: 85 },
  ];

  const signUp = (project_data) => {
    if (project_data.external_registration_required) {
      return (
        <Box>
          <Typography component="body2" color="text.secondary" gutterBottom>
            This project requires registration at {project_data.land_manager_name}'s site.<br/>
          </Typography>
          <Button color="success" variant="contained" size="large" component={Link} href={project_data.external_registration} target="_blank" rel="noopener">Sign up</Button>
        </Box>
      );
    } else {
      return (
        <Box>
          <Typography component="body2" color="text.secondary" gutterBottom>
            <Typography variant="h6">
              <b>Please email <Link href="mailto:howdy@frtrails.org">howdy@frtrails.org</Link> to sign up for this project.</b>
            </Typography>
            <i>We're currently building a sign up page to make this easier, sorry for the delay on its availability.</i>
          </Typography>
        </Box>
      );
    }
  }

  const WeatherNote = () => {
    const start = new Date(projectData.project_start);
    if (start.getTime() < May.getTime()) {
      return (
        <Box sx={{ width: '100%' }}>
          <Collapse in={open}>
            <Alert severity="info" action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpen(false); }} >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
                <AlertTitle>March & April</AlertTitle>
                We try not to cancel projects, but March and April being the wettest
                months of the year sometimes leads to getting rained or snowed out.
                We'll work if we can, and we may switch locations if this park isn't
                workable but another is. If we need to cancel we will notify you ASAP.
            </Alert>
          </Collapse>
          <Button disabled={open} size="small" variant="outlined" startIcon={<ThunderstormIcon/>} onClick={() => { setOpen(true); }} >
            Weather Note
          </Button>
        </Box>
      );
    }
  }

  function crewMemberTooltip() {
    return (
      <Tooltip disableFocusListener title="For projects with external registration like Jeffco Open Space, the crew member list is only retrieved periodically.">
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    );
  }

  const PageContent = () => {
    return (
      <Box margin="auto" sx={{ pt: 4, pb: 4, width: {xs:"100%", xl: "75%"} }}>
        <Typography component="p" variant="h4" color="text.primary" gutterBottom>
          {projectData.title}
        </Typography>
        <Typography component="p" variant="h5" color="text.primary" gutterBottom>
          {formatFullDate(projectData.project_start)}–{formatEnding(projectData.project_end)} {WeatherNote()}
        </Typography>
        <br/>
        <Grid container spacing={4}>
          <Grid xs={12} md={6} display="flex">
            <Typography component="p" color="text.primary" gutterBottom>
              <Typography component="p" variant="h6" color="text.primary">
                {projectData.park_name}—{projectData.trailhead_name} trailhead<br/>
              </Typography>
              {projectData.trailhead_address} <br/>
              Coordinates: {projectData.trailhead_coordinates} <br/>
            </Typography>
          </Grid>
          <Grid xs={12} md={6} display="flex">
            <Typography component="p" color="text.primary" gutterBottom>
              <Typography component="p" variant="h6" color="text.primary">
                Crew Details
              </Typography>
              Leader: {projectData.leader_first_name} {projectData.leader_last_name} <br/>
              Size: {projectData.max_crew_members} <br/>
            </Typography>
          </Grid>
        </Grid>
        <br/>
        <Typography component="p" color="text.primary" gutterBottom>
           <Typography component="p" variant="h6" color="text.primary">
            Project Details
           </Typography>
           {projectData.details}
        </Typography>
        <br/>
        {signUp(projectData)}
        <br/>
        <Typography component="p" color="text.primary" gutterBottom>
          <Typography component="p" variant="h6" color="text.primary">
            Crew Members {crewMemberTooltip()}
          </Typography>
          <Box sx={{ height: 360, width: 225 }} >
            <DataGrid
              autoheight
              rowHeight={25}
              rows={crewMembersData}
              columns={crew_members_columns}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              sortingMode="server"
              initialState={{
                  columns: { columnVisibilityModel: { id: false }},
                  pagination: { pageSize: 15, },
              }}
              localeText={{
                noRowsLabel: "No members yet"
              }}
            />
          </Box>
        </Typography>
      </Box>
    );
  }

  const ProjectBody = () => {
    if (isLoading) {
      return (<CircularProgress />);
    } else {
      return (<PageContent />);
    }
  }

  return (
    <Container maxWidth="xl" component="main">
      <ThemeProvider theme={theme}>
      <Header />
        <ProjectBody />
      <Footer />
      </ThemeProvider>
    </Container>
  );
}
