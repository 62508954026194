import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from "@mui/material/Container";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import { DataGrid } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// import Link from '@mui/material/Link';
import { Link as RouterLink } from "react-router-dom";

import Header from "../components/Header.js";
import Footer from "../components/Footer.js";

import {ThemeProvider} from "@mui/material/styles";
import theme from "../components/Theme";

import moment from 'moment';


export default function Projects() {
  const [upcomingProjectsData, setUpcomingProjectsData] = useState([])
  const [pastProjectsData, setPastProjectsData] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ProjectsBody()
  }, [isLoading])
  useEffect(() => {
    fetchUpcomingProjects()
  }, [])
  useEffect(() => {
    fetchPastProjects()
  }, [])

  const formatFullDate = (date) => moment(date).format("dddd MMMM Do, YYYY h:mm a");
  const formatEnding = (date) => moment(date).format("h:mm a");

  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  const handleRowClick = (param, event) => {
    event.stopPropagation();
  };

  const upcoming_project_columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'project_id', headerName: 'Project', width: 90,
      renderCell: (params) => {
        return (
          <Button variant="contained" size="small" component={RouterLink} to={"/project/" + params.value}>{params.value}</Button>
        );
      }
    },
    { field: 'project_start', headerName: 'Start', type: "dateTime", width: 250, valueGetter: ({ value }) => formatFullDate(value)},
    { field: 'project_end', headerName: 'End', type: "dateTime", width: 100, valueGetter: ({ value }) => formatEnding(value) },
    { field: 'title', headerName: 'Title', type: 'string', width: 250 },
    { field: 'park_name', headerName: 'Park', type: 'string', width: 150 },
    { field: 'accepting_crew_members', headerName: 'Signup Open', type: 'boolean', width: 100 },
  ];

  const past_project_columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'project_id', headerName: 'Project', width: 90,
      renderCell: (params) => {
        return (
          <Button variant="contained" size="small" component={RouterLink} to={"/project/" + params.value}>{params.value}</Button>
        );
      }
    },
    { field: 'project_start', headerName: 'Start', type: "dateTime", width: 250, valueGetter: ({ value }) => formatFullDate(value)},
    { field: 'project_end', headerName: 'End', type: "dateTime", width: 100, valueGetter: ({ value }) => formatEnding(value) },
    { field: 'title', headerName: 'Title', type: 'string', width: 250 },
    { field: 'park_name', headerName: 'Park', type: 'string', width: 150 },
    { field: 'completed', headerName: 'Completed', type: 'boolean', width: 100 },
    { field: 'canceled', headerName: 'Canceled', type: 'boolean', width: 100 },
  ];
  const maxWidth = 940 + 50; // 940 for fields, 50 padding

  const fetchUpcomingProjects = () => {
    setIsLoading(true);
    fetch("/api/projects", {method: "POST"})
      .then((res) => res.json())
      .then((result) => setUpcomingProjectsData(result.projects))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchPastProjects = () => {
    setIsLoading(true);
    fetch("/api/projects/past", {method: "POST"})
      .then((res) => res.json())
      .then((result) => setPastProjectsData(result.projects))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const PageContent = () => {
    return (
      <Box margin="auto" sx={{ pt: 4, pb: 4, maxWidth: maxWidth }}>
        <Typography variant="h4" component="p" align="justify" color="text.primary" gutterBottom>
            Upcoming Projects
        </Typography>
        <Typography variant="body1" component="p" align="justify" color="text.primary">
            Click on a project number for more details
        </Typography>
        <br/>
        {/* <Alert severity="info">
            <AlertTitle>March & April</AlertTitle>
            Based on past seasons, the dates in March and April may be cancelled
            based on ground conditions early in the season.
            <br/>
            We'll try to work if we can but the ground may be too muddy, frozen, still covered in snow, etc.
            If there are other locations we can work at, we may move projects around.
        </Alert>
        <br/> */}
         {/* <Typography variant="body1" component="p" align="justify" color="text.secondary">
            <i>Last updated: 2023-03-07</i>
        </Typography> */}

        {/* Make this box 350 in the offseason, 635 when there are active projects */}
        <Box sx={{ height: 635, width: '100%', maxWidth: maxWidth }} >
        {/* <Box sx={{ height: 350, width: '100%', maxWidth: maxWidth }} > */}
          <DataGrid
              rows={upcomingProjectsData}
              columns={upcoming_project_columns}
              rowsPerPageOptions={[10, 20]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              onCellClick={handleCellClick}
              onRowClick={handleRowClick}
              initialState={{
                  columns: { columnVisibilityModel: { id: false }},
                  pagination: { pageSize: 10, },
              }}
          />
        </Box>
        <br/>
        <Typography variant="h4" component="p" align="justify" color="text.primary" gutterBottom>
          Past Projects
        </Typography>
        <Box sx={{ height: 635, width: '100%', maxWidth: maxWidth }} >
          <DataGrid
              rows={pastProjectsData}
              columns={past_project_columns}
              rowsPerPageOptions={[10, 20 ]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              onCellClick={handleCellClick}
              onRowClick={handleRowClick}
              initialState={{
                  columns: { columnVisibilityModel: { id: false }},
                  pagination: { pageSize: 10, },
              }}
          />
        </Box>

      </Box>
 
    );
  }

  const ProjectsBody = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      );
    } else {
      return (<PageContent />);
    }
  }

  return (
    <Container maxWidth="xl" component="main">
      <ThemeProvider theme={theme}>
      <Header />
        <ProjectsBody />
      <Footer />
      </ThemeProvider>
    </Container>
  )
}
