import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Header from "../components/Header.js";
import Footer from "../components/Footer.js";

import {ThemeProvider} from "@mui/material/styles";
import theme from "../components/Theme";

import HikingImage from "../static/hiking-out.jpg";

export default function NotFound() {

  return (
    <Container maxWidth="xl" component="main">
      <ThemeProvider theme={theme}>
      <Header />

      <Box margin="auto" sx={{ pt: 4, pb: 4, width: {xs:"100%", xl: "75%"} }}>
        <Typography component="p" variant="h4" color="text.primary" gutterBottom>
          Sorry, that page was not found.
        </Typography>
        <br/>
        <img style={{width: "100%"}} src={HikingImage} alt="Hiking out of North Table Mountain" />
      </Box>

      <Footer />
      </ThemeProvider>
    </Container>
  );
}
