import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import AboutOrganization from './pages/AboutOrganization';
import Contact from './pages/Contact';
import NewsletterSignup from './pages/Newsletter';
import PrivacyPolicy from './pages/Privacy';
import Projects from './pages/Projects';
import Project from './pages/Project';
import NotFound from './pages/404';

import ReactGA from 'react-ga';
const TRACKING_ID = "G-48MC7NJQVH";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);


const Main = () => {
  return (
    <Routes>
      <Route exact path='/' element={<Home/>} />
      <Route exact path='/about/organization' element={<AboutOrganization/>} />
      <Route exact path='/contact' element={<Contact/>} />
      <Route exact path='/newsletter' element={<NewsletterSignup/>} />
      <Route exact path='/privacy' element={<PrivacyPolicy/>} />
      <Route exact path='/projects' element={<Projects/>} />
      <Route exact path='/project/:id' element={<Project/>} />
      <Route path="*" element={<NotFound/>} />
    </Routes>
  );
}

export default Main;
