import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';

import InstagramIcon from '@mui/icons-material/Instagram';
//import NewsletterDialog from './NewsletterDialog';

import { Link as RouterLink } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="text.light" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://frtrails.org/">
        Front Range Trails Conservancy
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer() {
  return (
    // <Box margin="auto" sx={{ pt: 4, pb: 4, width: "60%" }} ></Box>
    <Box component="footer" margin="auto"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 4,
        py: [1, 3],
        // width: "75%",
        bgcolor: "primary.main"
      }}
    >
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <Grid container justifyContent="center">
        <Grid item sm={2} xl={3} key="Empty"></Grid>
        <Grid item sm={5} xl={3} key="About">
          <Box sx={{ pl: 2 }}>
            <Typography variant="h6" color="text.light" gutterBottom>
              About
            </Typography>
            <ul>
              <li key="Organization">
                <Link component={RouterLink} to="/about/organization">
                  <Typography variant="subtitle1" color="text.light">
                    Organization
                  </Typography>
                </Link>
              </li>
              <li key="Projects">
                <Link component={RouterLink} to="/projects">
                  <Typography variant="subtitle1" color="text.light">
                    Projects
                  </Typography>
                </Link>
              </li>
              <li key="Contact Us">
                <Link component={RouterLink} to="/contact">
                  <Typography variant="subtitle1" color="text.light">
                    Contact us
                  </Typography>
                </Link>
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid item sm={5} xl={3} key="Follow Us">
          <Box sx={{ pl: 2 }}>
            <Typography variant="h6" color="text.light" gutterBottom>
              Follow Us
            </Typography>
            <ul>
              <li>
                <Link component={RouterLink} to="/newsletter" variant="subtitle1" color="text.light">Newsletter</Link>
              </li>
              <li key="Instagram">
                <Link href="https://instagram.com/frtrails" variant="subtitle1" color="text.light" target="_blank" rel="noopener">
                <InstagramIcon /> @frtrails
                </Link>
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid item sm={2} xl={3} key="Empty2"></Grid>
      </Grid>
      <br />
      <Copyright sx={{ mt: 5 }} />
    </Box>
  );
}

export default Footer;
