import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import './Header.css';
import HeaderLogo from '../static/wide-logo-light-text-2x.png';

import { Link as RouterLink } from "react-router-dom";

function Header() {
  return (
    <Box>
      <AppBar
      position="static"
      color="primary"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <Box className="Logo">
          <Link component={RouterLink} to="/">
            <img src={HeaderLogo} alt="FRTC Logo" />
          </Link>
        </Box>
        <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          {/* Without this Typography, the link gets left aligned... */}
        </Typography>
        <Box m={1} display="flex" alignItems="flex-end">
          <Button color="inherit" component={RouterLink} to="/projects">
            Projects
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
    </Box>
  );
}

export default Header;
