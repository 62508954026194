import * as React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Link as RouterLink } from "react-router-dom";

import HeaderImage from "../static/HeaderImage.jpg";

function FeaturedImage() {

  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: "grey.800",
        color: "#fff",
        mb: 4,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        backgroundImage: `url(${HeaderImage})`,
      }}
    >
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: "none" }} src={HeaderImage} alt="Trail crews at Mt. Falcon" />}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,.3)",
        }}
      />
      <Grid container>
        <Grid item md={8}>
          <Box
            sx={{
              position: "relative",
              p: { xs: 3, md: 6 },
              pt: { md: 20 },
              pr: { md: 0 },
            }}
          >
            <Typography variant="h5" color="inherit" paragraph>
              Front Range Trails Conservancy is a nonprofit that protects and advances public lands along Colorado's Front Range by effecting equitable, effective, and lasting change through trail and advocacy projects.
            </Typography>
            <Button variant="contained" size="small" component={RouterLink} to="about/organization">Learn more</Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default FeaturedImage;