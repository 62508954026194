import {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import FeaturedImage from "../components/FeaturedImage.js";
//import NewsletterDialog from "../components/NewsletterDialog.js";

import JCOSImage from "../static/JCOS.png";
import LakewoodImage from "../static/Lakewood.png";
import SufferBetterImage from "../static/SufferBetter.png";

import {ThemeProvider} from "@mui/material/styles";
import theme from "../components/Theme";

import { Link as RouterLink } from "react-router-dom";

import { DataGrid } from '@mui/x-data-grid';
import moment from "moment";

function LandPartnersSnippet() {
  return (
    <Box margin="auto" sx={{ pt: 4, pb: 4, width: "75%" }} >
      <Typography component="h4" variant="h4" align="center" color="text.primary" gutterBottom>
        Land Partners
      </Typography>
      <Typography variant="body1" align="justify" color="text.secondary" component="p">
        FRTC partners with the following agencies to help complete trail building,
        maintenance, and restoration projects. From touching up rolling dip drains with
        a shovel to building retaining walls out of rock, volunteer trail crews make
        a huge impact on public lands.
      </Typography>

      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          sx={{
            // borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            // borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            mt: 1, py: [1, 1],}}
          spacing={2}
        >
          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
            <Card>
              <CardMedia component="img" sx={{mt: 1, ml: 1, width: 250}} image={JCOSImage} title="Jefferson County Open Space" />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Headquartered in Golden, the Jeffco Open Space system includes 56,000 acres of preserved land, 27 parks, and 261 miles of trail.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">
                  <Link href="https://www.jeffco.us/814/Open-Space" target="_blank" rel="noopener">
                    Jeffco.us
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
            <Card>
              <CardMedia component="img" sx={{mt: 1, ml: 1, width: 250}} image={LakewoodImage} title="Lakewood Parks" />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Lakewood is home to 113 parks and 240 miles of trails, totaling over 7,400 acres of open space.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">
                  <Link href="https://www.lakewood.org/Government/Departments/Community-Resources/Parks-Forestry-and-Open-Space" target="_blank" rel="noopener">
                    Lakewood.gov
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function PartnersSnippet() {
  return (
    <Box margin="auto" sx={{ pt: 4, pb: 4, width: "60%" }} >
      <Typography component="h4" variant="h4" align="center" color="text.primary" gutterBottom>
        Partners
      </Typography>
      <Typography variant="body1" align="justify" color="text.secondary" component="p">
        FRTC is one piece of a community of people, organizations, and corporations
        that care about public lands. We're always looking to partner with those that
        share our love so we can make things better together.
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          sx={{
            // borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            // borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            mt: 1, py: [1, 1],}}
          spacing={2} justifyContent="center" alignItems="center"
        >
          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
            <Card>
              <CardMedia component="img" sx={{mt: 1, ml: 1, width: 250}} image={SufferBetterImage} title="Suffer Better" />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Suffer Better's mission is to tap the inexhaustible power, focus,
                  dedication, and commitment of the endurance athlete community
                  to preserve and protect our wild, natural environment,
                  work with and support local nonprofits, and improve local communities.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">
                  <Link href="https://sufferbetter.com/" target="_blank" rel="noopener">
                    sufferbetter.com
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
  </Box>
  );
}


const event_columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'time', headerName: 'Time', type: "dateTime", width: 175, valueGetter: ({ value }) => new Date(value).toLocaleString() },
    { field: 'description', headerName: 'Description', type: 'string', width: 300 },
    { field: 'location', headerName: 'Location', type: 'string', width: 400 },
];

const eventsMaxWidth = 875 + 50; // 875 fields, 50 padding

const event_rows = [
    {id:  0, time: "2023-03-02T16:30:00.000-07:00", description: "Jeffco Trails Volunteer Fair", location: "Arbor House, 14600 W 32nd Ave, Golden"},
    {id:  1, time: "2023-03-08T17:00:00.000-07:00", description: "Lakewood Parks Volunteer Fair", location: "Bear Creek Lake Visitors Center"},
];

function UpcomingEventsSnippet() {
  return (
    <Box margin="auto" sx={{ pt: 4, pb: 4 }} >
       <Typography component="h4" variant="h4" align="center" color="text.primary" gutterBottom>
        Upcoming Events
       </Typography>
       <Typography component="p" variant="body1" align="center" color="text.primary" gutterBottom>
        Along with trail projects, we'll be out recruiting, promoting, and advocating for trails
        at the following events:
       </Typography>

       {/* Width of this box is set to match the sum of the column widths */}
       {/* <Box margin="auto" sx={{ width: "75%" }} > */}
       <Box margin="auto" sx={{ width: { xs: "75%", xl: "65%" }, maxWidth: eventsMaxWidth  }} >
          <DataGrid
              autoHeight
            rows={event_rows}
            columns={event_columns}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            initialState={{
              columns: { columnVisibilityModel: { id: false }},
            }}
          />
       </Box>
    </Box>
  );
}

function Home() {
  const [projectsData, setProjectsData] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    UpcomingProjectsSnippet()
  }, [isLoading])

  const formatFullDate = (date) => moment(date).format("dddd MMMM Do, YYYY h:mm a");

  const project_columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'project_id', headerName: 'Project', width: 90,
      renderCell: (params) => {
        return <Button variant="contained" size="small" component={RouterLink} to={"/project/" + params.value}>{params.value}</Button>
      }
    },
    { field: 'project_start', headerName: 'Start', type: "dateTime", width: 250, valueGetter: ({ value }) => formatFullDate(value)},
    { field: 'title', headerName: 'Title', type: 'string', width: 250 },
    { field: 'park_name', headerName: 'Park', type: 'string', width: 150 },
    { field: 'accepting_crew_members', headerName: 'Signup Open', type: 'boolean', width: 100 },
  ];

  const projectsMaxWidth = 840 + 50 ; // 840 fields width, 50 padding

  useEffect(() => {
    fetchProjects()
  }, [])

  const fetchProjects = () => {
    setIsLoading(true);
    fetch("/api/front_page_projects", {method: "POST"})
      .then((res) => res.json())
      .then((result) => setProjectsData(result.projects))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const UpcomingProjectsSnippet = () => {
    const handleCellClick = (param, event) => {
      event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
      event.stopPropagation();
    };

    if (isLoading) {
      return (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      );
    } else {
      return (
        <Box margin="auto" sx={{ pt: 4, pb: 4 }} >
          <Typography component="h4" variant="h4" align="center" color="text.primary" gutterBottom>
            Upcoming Projects
          </Typography>
          <Typography component="p" variant="body1" align="center" color="text.primary" gutterBottom>
            Check out our full projects page to see the entire schedule.
            <br/>
            <Button variant="contained" size="small" component={RouterLink} to="projects">All Projects</Button>
          </Typography>

          {/* Width of this box is set to match the sum of the column widths */}
          {/* <Box margin="auto" sx={{ width: "75%" }} > */}
          <Box margin="auto" sx={{ width: { xs: "75%", xl: "65%" }, maxWidth: projectsMaxWidth }} >
              <Typography component="p" variant="body2" color="text.secondary" gutterBottom>
                Next 5 upcoming projects:
              </Typography>
              <DataGrid
                  autoHeight
                rows={projectsData}
                columns={project_columns}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                onCellClick={handleCellClick}
                onRowClick={handleRowClick}
                initialState={{
                  columns: { columnVisibilityModel: { id: false }},
                }}
              />
          </Box>
        </Box>
      );
    }
  }

  return (
    <Container maxWidth="xl" component="main">
      <ThemeProvider theme={theme}>
        <Header/>
        <FeaturedImage/>
        <UpcomingProjectsSnippet/>
        {/* <UpcomingEventsSnippet/> */}
        <LandPartnersSnippet/>
        <PartnersSnippet/>
        <Footer/>
      </ThemeProvider>
    </Container>
  );
}

export default Home;
